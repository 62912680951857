.sidebar-link.active{
    background-color: rgba(254,0,0,255) !important;
    color:white !important;
}

.sidebar-link:hover{
    color: rgba(254,0,0,255) !important;
}
.sidebar-link:hover i{
    color: rgba(254,0,0,255) !important;
   
}

.sidebar-link.has-arrow:hover, .sidebar-link.has-arrow:active{
    background-color: rgba(0,0,0,0.9) !important;
    /* color:white !important; */
}